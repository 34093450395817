import { render, staticRenderFns } from "./westudyAvalonPage.vue?vue&type=template&id=de5d4efc&scoped=true&"
import script from "./westudyAvalonPage.vue?vue&type=script&lang=js&"
export * from "./westudyAvalonPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de5d4efc",
  null
  
)

export default component.exports