<template>
  <div>

    <template    v-for="(block,i) in blocks">
      <view-block
          v-cloak
          v-if="visibilityCondition(block.config) && !block.parent"
          :block="block"
          :elements="getBlockElements(block.id)"
          style="transition: all 0.3s ease"
          :key="'block_' + i"
          :overlap="i === 0"
          :is-first="i === 0"
          :children="getBlockChildren(block.id)"
          :children-elements="getChildrenElements(block.id)"
          :ref="`#${block.id}`"
          :id="`#${block.id}`"
      />
    </template>

  </div>
</template>

<script>
import {mapActions} from "vuex";
import defaultConfig from "@/assets/json/avalon/defaultConfig.json";
import viewBlock from "@/components/AvalonEditor/View/blocks/viewBlock.vue";

export default {
  name: "westudyAvalonPage",
  components : {
    viewBlock,
  },
  props : {
    pageAlias : {}
  },
  data() {
    return {
      entity : {},
      blocks : [],
      elements : []
    }
  },
  methods : {
    ...mapActions('avalonPage' , [
        'GET_PAGE_WESTUDY'
    ]),

    visibilityCondition(config) {
      if (!config) {
        return true
      }
      if ( this.SM ) {
        if ( config.visibility_mobile === false ) {
          return false
        }
      }

      if ( !this.SM ) {
        if ( config.visibility_desktop === false ) {
          return false
        }
      }

      return true
    },

    getBlockElements(id) {
      if (this.elements.length === 0 ) {
        return []
      }
      return this.elements.filter(el=>el.block_id === id)
    },
    getBlockChildren(parentId) {
      if ( this.blocks.length === 0 ) {
        return []
      }
      return this.blocks.filter(el => el.parent === parentId)
    },
    getChildrenElements(parentId) {
      if ( this.getBlockChildren(parentId).length === 0 ) {
        return []
      }
      let items = [];
      this.getBlockChildren(parentId).forEach(block => {
        items = [...items , ...this.getBlockElements(block.id)]
      })
      return items
    },

    async getPage() {
      let result = await this.GET_PAGE_WESTUDY(this.pageAlias)
      if (!result) {
        return
      }
      this.blocks = result.blocks
      this.elements = result.elements
    }

  },
  mounted() {
    this.getPage()
  },
  beforeMount() {
    this.$store.state.avalon.editorMode = false
    this.$store.state.avalon.sideMenu = false
    this.$store.state.avalon.defaultConfig = defaultConfig
    this.$store.state.avalon.config = this.COPY(defaultConfig)
    this.LOAD_FONTS()

  },
}
</script>


<style scoped>

</style>